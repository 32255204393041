export const ADD_CO_BRAND = 'ADD_CO_BRAND';
export const UPLOAD_CO_BRAND_LOGO = 'UPLOAD_CO_BRAND_LOGO';
export const RESET_UPLOAD_CO_BRAND_LOGO = 'RESET_UPLOAD_CO_BRAND_LOGO';
export const GET_CO_BRANDS = 'GET_CO_BRANDS';
export const DELETE_CO_BRAND = 'DELETE_CO_BRAND';
export const RESET_DELETE_CO_BRAND = 'RESET_DELETE_CO_BRAND';
export const GET_CO_BRAND = 'GET_CO_BRAND';
export const UPDATE_CO_BRAND = 'UPDATE_CO_BRAND';
export const RESET_CO_BRAND = 'RESET_CO_BRAND';
export const GET_DEBUGGING = 'GET_DEBUGGING';
