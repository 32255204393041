import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import LoadingIndicator from '../../common/components/LoadingIndicator';
import LocalizedStrings from 'react-localization';
import { toast } from 'react-toastify';
import { getDebugging } from '../admin-actions';
import UserDetails from './UserDetails';

let strings = new LocalizedStrings({
  en: {
    dashboard: 'Debug',
    totalUsers: 'Total Registered Users',
    totalVouchers: 'Total Fitting Codes',
    totalPayments: 'Total Payments',
    viewAll: 'View All',
    code: 'Code',
    email: 'Email',
    date: 'Date',
    status: 'Status',
    amount: 'Amount',
    promoCode: 'Has Promo Code',
    transactionId: 'Transaction ID',
    noData: 'No data available',
    loading: 'Loading data...',
    error: 'Error loading data',
    active: 'Active',
    used: 'Used',
    expired: 'Expired',
    freeVouchers: 'Free Fitting Codes',
    paidVouchers: 'Paid Fitting Codes',
    registeredUserPayments: 'Registered User Payments',
    search: 'Search',
    refresh: 'Refresh Data',
    lastUpdated: 'Last Updated',
    userType: 'User Type',
    fitter: 'Fitter',
    player: 'Player',
    admin: 'Admin',
    apply: 'Apply',
    clear: 'Clear',
    recentVouchers: 'Fitting Codes (Ballnamic)',
    recentExternalVouchers: 'Fitting Codes (Voucherify)',
    isFreeVoucher: 'Free Fitting Code',
    recentPayments: 'Payments (Ballnamic)',
    recentExternalPayments: 'Payments (Braintree)',
    filterApplied: 'Filter Applied',
    filterHelp: 'Analyze user, payment and fitting codes information',
    fittingType: 'Fitting Type',
    fittingCode: 'Fitting Code',
  },
});

const formatFittingType = (fittingType) => {
  if (!fittingType) return 'Legacy';
  switch (fittingType) {
    case 'basic':
      return 'single_fitting';
    case 'standard':
      return '3_fittings';
    case 'premium':
      return '5_fittings';
    default:
      return fittingType;
  }
};

const Debugging = ({ dispatch, debuggingData }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    totalUsers: 0,
    freeVouchers: 0,
    paidVouchers: 0,
    totalVouchers: 0,
    totalVoucherifyVouchers: 0,
    totalBraintreePayments: 0,
    totalPayments: 0,
  });
  const [filteredVouchers, setFilteredVouchers] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [filteredVoucherifyVouchers, setFilteredVoucherifyVouchers] = useState([]);
  const [filteredBraintreePayments, setFilteredBraintreePayments] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [isRegisteredUser, setIsRegisteredUser] = useState(false);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const [selectedVoucherCode, setSelectedVoucherCode] = useState(null);

  // User filter states
  const [emailFilterValue, setEmailFilterValue] = useState('');
  const [fittingCodeFilterValue, setFittingCodeFilterValue] = useState('');
  const [appliedEmailFilter, setAppliedEmailFilter] = useState('');
  const [appliedFittingCodeFilter, setAppliedFittingCodeFilter] = useState('');

  const fetchDashboardData = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      await dispatch(getDebugging(
        appliedEmailFilter || '',
        appliedFittingCodeFilter || ''
      ));
      setLastUpdated(new Date());
    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError('Failed to load dashboard data. Please try again.');
      toast.error('Error loading dashboard data');
    } finally {
      setLoading(false);
    }
  }, [appliedEmailFilter, appliedFittingCodeFilter, dispatch]);

  // Add new useEffect to handle debuggingData updates
  useEffect(() => {
    if (debuggingData) {

      const fittingCodes = debuggingData.fittingCodes || [];
      const payments = debuggingData.payments || [];

      const voucherifyFittingCodes = debuggingData.voucherifyFittingCodes || [];
      const braintreePayments = debuggingData.braintreePayments || [];

      // Highlight searched fitting code if filtering by code
      if (appliedFittingCodeFilter) {
        const matchingVoucher = fittingCodes.find(code => code?.code?.toUpperCase() === appliedFittingCodeFilter?.toUpperCase());
        if (matchingVoucher) {
          setSelectedVoucherCode(matchingVoucher.code);
          setSelectedTransactionId(matchingVoucher.braintree_transaction_id);
        }
      } else if (appliedEmailFilter) {
        setSelectedVoucherCode('');
        setSelectedTransactionId('');
      }

      // Separate free and paid vouchers based on braintree_transaction_id
      const freeVouchers = fittingCodes.filter(code => !code.braintree_transaction_id);
      const paidVouchers = fittingCodes.filter(code => code.braintree_transaction_id);

      setIsRegisteredUser(debuggingData.isRegisteredUser);
      setFilteredVouchers(fittingCodes);
      setFilteredPayments(payments);
      setFilteredVoucherifyVouchers(voucherifyFittingCodes);
      setFilteredBraintreePayments(braintreePayments);

      setStats({
        totalVouchers: fittingCodes.length || 0,
        totalPayments: payments.length || 0,
        freeVouchers: freeVouchers.length || 0,
        paidVouchers: paidVouchers.length || 0,
        totalVoucherifyVouchers: voucherifyFittingCodes.length || 0,
        totalBraintreePayments: braintreePayments.length || 0,
      });
    }
  }, [debuggingData, appliedEmailFilter, appliedFittingCodeFilter]);

  const handleFilterApply = () => {
    const trimmedEmail = emailFilterValue.trim();
    const trimmedFittingCode = fittingCodeFilterValue.trim();

    if (!trimmedEmail && !trimmedFittingCode) {
      toast.warning('Please enter an email or fitting code');
      return;
    }

    setAppliedEmailFilter(trimmedEmail);
    setAppliedFittingCodeFilter(trimmedFittingCode);
    setLastUpdated(new Date());
    // fetchDashboardData will be called automatically due to useEffect
  };

  const handleFilterClear = () => {
    setEmailFilterValue('');
    setFittingCodeFilterValue('');
    setAppliedEmailFilter('');
    setAppliedFittingCodeFilter('');
    setSelectedTransactionId(null);
    setSelectedVoucherCode(null);
    // fetchDashboardData will be called automatically due to useEffect
  };

  // Add useEffect to watch for filter changes
  useEffect(() => {
    fetchDashboardData();
  }, [appliedEmailFilter, appliedFittingCodeFilter, fetchDashboardData]);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  };

  if (loading && !lastUpdated) {
    return (
      <div className="admin-dashboard">
        <h1 className="admin-dashboard__title">{strings.dashboard}</h1>
        <div className="admin-dashboard__loading">
          <LoadingIndicator />
          <p>{strings.loading}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="admin-dashboard">
      <div className="admin-dashboard__header">
        <h1 className="admin-dashboard__title">{strings.dashboard}</h1>
      </div>
      <div className="admin-dashboard__date-filter-help">
        {strings.filterHelp}
      </div>

      <div className="admin-dashboard__date-filter">
        <div className="admin-dashboard__date-filter-header">
          <div className="admin-dashboard__date-filter-label">
            {strings.filterByDate}
          </div>
        </div>
        <div className="admin-dashboard__date-filter-inputs">
          <div className="admin-dashboard__date-picker-container">
            <label>{strings.email}</label>
            <input
              type="text"
              className="admin-dashboard__user-filter-input"
              value={emailFilterValue}
              onChange={(e) => setEmailFilterValue(e.target.value)}
              placeholder={strings.email}
            />
          </div>
          <div className="admin-dashboard__date-picker-container">
            <label>{strings.fittingCode}</label>
            <input
              type="text"
              className="admin-dashboard__user-filter-input"
              value={fittingCodeFilterValue}
              onChange={(e) => setFittingCodeFilterValue(e.target.value)}
              placeholder={strings.fittingCode}
            />
          </div>
        </div>
        <div className="admin-dashboard__date-filter-buttons">
          <button
            className="admin-dashboard__date-filter-apply"
            onClick={handleFilterApply}
            disabled={loading}
          >
            {strings.apply}
          </button>
          <button
            className="admin-dashboard__refresh-button"
            onClick={handleFilterClear}
            disabled={loading}
          >
            {strings.clear}
          </button>
          <button
            className="admin-dashboard__refresh-button"
            onClick={fetchDashboardData}
            disabled={loading}
          >
            {strings.refresh}
          </button>
        </div>
        {(appliedEmailFilter || appliedFittingCodeFilter) && (
          <div className="admin-dashboard__user-filter-summary">
            {appliedEmailFilter && appliedFittingCodeFilter ? (
              <>Showing data for email: {appliedEmailFilter} and fitting code: {appliedFittingCodeFilter}</>
            ) : appliedEmailFilter ? (
              <>Showing data for email: {appliedEmailFilter}</>
            ) : (
              <>Showing data for fitting code: {appliedFittingCodeFilter}</>
            )}
            <div className={`admin-dashboard__user-status ${isRegisteredUser ? 'registered' : 'guest'}`}>
              User Status: {isRegisteredUser ? 'Registered User' : 'Guest User'}
            </div>
          </div>
        )}
        {lastUpdated && (filteredVouchers.length > 0 || filteredPayments.length > 0) && (
          <div className="admin-dashboard__last-updated">
            {strings.lastUpdated}: {formatDate(lastUpdated)}
          </div>
        )}
      </div>

      {error && (
        <div className="admin-dashboard__error">
          {error}
        </div>
      )}

      {(appliedEmailFilter || appliedFittingCodeFilter) && (debuggingData?.braintreePayments?.length > 0 || debuggingData?.payments?.length > 0 || debuggingData?.voucherifyFittingCodes?.length > 0 || debuggingData?.fittingCodes?.length > 0 || debuggingData?.isRegisteredUser || debuggingData?.receivedFittingCodes?.length > 0) && (
        <UserDetails
          userEmail={debuggingData?.email}
          userProfile={debuggingData?.user?.[0]}
          createdAt={debuggingData?.user?.[0]?.created_at}
          emailVerified={debuggingData?.user?.[0]?.verified_at !== null}
          shares={debuggingData.shares}
          receivedShares={debuggingData.receivedFittingCodes}
          fittingResults={debuggingData.results}
          loading={loading}
          isRegisteredUser={isRegisteredUser}
        />
      )}

      {(appliedEmailFilter || appliedFittingCodeFilter) && <div className="admin-dashboard__tables">
        <div className="admin-dashboard__table-container">
          <div className="admin-dashboard__table-header">
            <div className="admin-dashboard__table-title-section">
              <h2 className="admin-dashboard__table-title">
                {strings.recentPayments}
              </h2>
              <div className="admin-dashboard__table-stats">
                Total: {stats.totalPayments}
              </div>
            </div>
          </div>

          {loading ? (
            <div className="admin-dashboard__loading-indicator">
              <LoadingIndicator />
            </div>
          ) : filteredPayments.length > 0 ? (
            <table className="admin-dashboard__table">
              <thead>
                <tr>
                  <th>{strings.transactionId}</th>
                  <th>{strings.date}</th>
                  <th>{strings.promoCode}</th>
                  <th>{strings.fittingType}</th>
                  <th>{strings.status}</th>
                </tr>
              </thead>
              <tbody>
                {filteredPayments.map((payment) => (
                  <tr key={payment.id || payment.transaction_id}
                    onClick={() => {
                      setSelectedTransactionId(payment.braintree_transaction_id);
                      setSelectedVoucherCode(null);
                    }}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: selectedTransactionId === payment.braintree_transaction_id ? 'rgba(80, 87, 255, 0.25)' : 'inherit'
                    }}>
                    <td>{payment.braintree_transaction_id}</td>
                    <td>{formatDate(payment.created_at)}</td>
                    <td>{payment.promoCode || 'No'}</td>
                    <td>{formatFittingType(payment.fitting_type)}</td>
                    <td>
                      <span className="status-badge">
                        {payment.status || 'Approved'}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="admin-dashboard__no-data">{strings.noData}</div>
          )}
        </div>
        <div className="admin-dashboard__table-container">
          <div className="admin-dashboard__table-header">
            <div className="admin-dashboard__table-title-section">
              <h2 className="admin-dashboard__table-title">
                {strings.recentVouchers}
              </h2>
              <div className="admin-dashboard__table-stats">
                Total: {stats.totalVouchers} ({strings.freeVouchers}: {stats.freeVouchers} | {strings.paidVouchers}: {stats.paidVouchers})
              </div>
            </div>
          </div>

          {loading ? (
            <div className="admin-dashboard__loading-indicator">
              <LoadingIndicator />
            </div>
          ) : filteredVouchers.length > 0 ? (
            <table className="admin-dashboard__table">
              <thead>
                <tr>
                  <th>{strings.code}</th>
                  <th>{strings.transactionId}</th>
                  <th>{strings.isFreeVoucher}</th>
                  <th>{strings.date}</th>
                  <th>{strings.status}</th>
                </tr>
              </thead>
              <tbody>
                {filteredVouchers.map((voucher) => (
                  <tr key={voucher.id || voucher.code}
                    onClick={() => {
                      setSelectedTransactionId(voucher.braintree_transaction_id);
                      setSelectedVoucherCode(voucher.code);
                    }}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: selectedTransactionId === voucher.braintree_transaction_id || selectedVoucherCode === voucher.code ? 'rgba(80, 87, 255, 0.25)' : 'inherit'
                    }}>
                    <td>{voucher.code}</td>
                    <td>{voucher.braintree_transaction_id}</td>
                    <td>{voucher.braintree_transaction_id ? 'No' : 'Yes'}</td>
                    <td>{formatDate(voucher.created_at)}</td>
                    <td>
                      <span className="status-badge">
                        {voucher.status || 'Active'}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="admin-dashboard__no-data">{strings.noData}</div>
          )}
        </div>
        <div className="admin-dashboard__table-container">
          <div className="admin-dashboard__table-header">
            <div className="admin-dashboard__table-title-section">
              <h2 className="admin-dashboard__table-title">
                {strings.recentExternalPayments}
              </h2>
              <div className="admin-dashboard__table-stats">
                Total: {stats.totalBraintreePayments}
              </div>
            </div>
          </div>

          {loading ? (
            <div className="admin-dashboard__loading-indicator">
              <LoadingIndicator />
            </div>
          ) : filteredBraintreePayments.length > 0 ? (
            <table className="admin-dashboard__table">
              <thead>
                <tr>
                  <th>{strings.transactionId}</th>
                  <th>{strings.date}</th>
                  <th>{strings.amount}</th>
                  <th>{strings.status}</th>
                </tr>
              </thead>
              <tbody>
                {filteredBraintreePayments.map((payment) => (
                  <tr key={payment.id || payment.transaction_id}
                    onClick={() => {
                      setSelectedTransactionId(payment.id);
                      setSelectedVoucherCode(null);
                    }}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: selectedTransactionId === payment.id ? 'rgba(80, 87, 255, 0.25)' : 'inherit'
                    }}>
                    <td>{payment.id}</td>
                    <td>{formatDate(payment.createdAt)}</td>
                    <td>${payment.amount}</td>
                    <td>
                      <span className="status-badge">
                        {payment.processorResponseText}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="admin-dashboard__no-data">{strings.noData}</div>
          )}
        </div>
        <div className="admin-dashboard__table-container">
          <div className="admin-dashboard__table-header">
            <div className="admin-dashboard__table-title-section">
              <h2 className="admin-dashboard__table-title">
                {strings.recentExternalVouchers}
              </h2>
              <div className="admin-dashboard__table-stats">
                Total: {stats.totalVoucherifyVouchers}
              </div>
            </div>
          </div>

          {loading ? (
            <div className="admin-dashboard__loading-indicator">
              <LoadingIndicator />
            </div>
          ) : filteredVoucherifyVouchers.length > 0 ? (
            <table className="admin-dashboard__table">
              <thead>
                <tr>
                  <th>{strings.code}</th>
                  <th>{strings.transactionId}</th>
                  <th>{strings.date}</th>
                  <th>{strings.status}</th>
                </tr>
              </thead>
              <tbody>
                {filteredVoucherifyVouchers.map((voucher) => (
                  <tr key={voucher.id || voucher.code}
                    onClick={() => {
                      setSelectedTransactionId(voucher.braintree_transaction_id);
                      setSelectedVoucherCode(voucher.code);
                    }}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: selectedTransactionId === voucher.braintree_transaction_id || selectedVoucherCode === voucher.code ? 'rgba(80, 87, 255, 0.25)' : 'inherit'
                    }}>
                    <td>{voucher.code}</td>
                    <td>{voucher.braintree_transaction_id}</td>
                    <td>{formatDate(voucher.created_at)}</td>
                    <td>
                      <span className="status-badge">
                        {voucher.status || 'Active'}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="admin-dashboard__no-data">{strings.noData}</div>
          )}
        </div>
      </div>
      }
    </div>
  );
};

export default connect(state => ({
  loggedInUser: state.auth.loggedInUser,
  debuggingData: state.admin.debugging,
}))(Debugging);