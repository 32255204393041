import client from '../client';
import uploadFile from '../common/utils/uploadFile';
import config from '../config';

import {
  ADD_CO_BRAND,
  DELETE_CO_BRAND,
  GET_CO_BRAND,
  GET_CO_BRANDS,
  RESET_CO_BRAND,
  RESET_DELETE_CO_BRAND,
  RESET_UPLOAD_CO_BRAND_LOGO,
  UPDATE_CO_BRAND,
  UPLOAD_CO_BRAND_LOGO,
  GET_DEBUGGING,
} from './admin-action-constants';

export const addCoBrand = values => ({
  type: ADD_CO_BRAND,
  promise: client.post('co-brands', values),
});

export function getDebugging(email, fittingCode) {
  return {
    type: GET_DEBUGGING,
    promise: client.post('admin/debugging', {
      email,
      fittingCode
    }),
  };
}

export const uploadCoBrandLogo = (file, ext) => ({
  type: UPLOAD_CO_BRAND_LOGO,
  promise: uploadFile(config.fileStorage.cobrandingUploadContainer, file, ext),
});

export const resetUploadCoBrandLogo = () => ({
  type: RESET_UPLOAD_CO_BRAND_LOGO,
});

export const getCoBrand = coBrandId => ({
  type: GET_CO_BRAND,
  promise: client.get(`co-brands/${coBrandId}`),
});

export const getCoBrands = () => ({
  type: GET_CO_BRANDS,
  promise: client.get('co-brands'),
});

export const deleteCoBrand = id => ({
  type: DELETE_CO_BRAND,
  promise: client.delete(`co-brands/${id}`),
});

export const resetDeleteCoBrand = () => ({
  type: RESET_DELETE_CO_BRAND,
});

export const updateCoBrand = (coBrandId, values) => ({
  type: UPDATE_CO_BRAND,
  promise: client.put(`co-brands/${coBrandId}`, values),
});

export const resetCoBrand = () => ({
  type: RESET_CO_BRAND,
});
