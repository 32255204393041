export default window.__config || {
  analytics: {
    measurementId: 'G-WCZFHPB9EP',
  },
  apiUrl: 'http://localhost:9000/',
  auth: {
    clientId: 'synapse1',
    redirectUri: 'https://develop.ballfitting.com/post-login',
    postLogoutRedirectUri: 'https://develop.ballfitting.com/post-logout',
    domain: 'https://auth.develop.ballfitting.com',
    scope: 'openid email offline_access',
    responseType: 'code',
  },
  contentful: {
    space: '74igzcw7kfyq',
    accessToken: 'w6O3TuLe6kqHVBNDPHhv3Aa2UVvE37P1HNJzzdVnLPU',
    previewToken: '0_zVEKQZafrFhjxnFg3bxDLLMCRPZadYJwXX0jB3V_c',
    environment: 'dev',
  },
  sentry: {
    dsn: 'https://10c980216aff244fc52f81a5aa739479@o4509007872327680.ingest.us.sentry.io/4509007884779520',
  },
  gtm: {
    id: 'GTM-NRDFVNR',
    auth: 'e_JMRBZ4BkLbZv9LoHBihg',
    preview: 'env-8',
  },
  appDomain: 'develop.ballfitting.com',
  fileStorage: {
    driver: 'S3',
    cobrandingUploadContainer: 'dev-ballnamic-cobranding',
  },
  payPalEnabled: true,
  applePayEnabled: true,
  googlePayEnabled: true,
  googleMerchantId: 'BCR2DN4T4O45VCIC',
  agentsUrl: 'https://dev-ballnamic-redirect-json-smesh.s3.us-west-2.amazonaws.com/agents.json',
};
