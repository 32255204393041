import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import { withRouter } from 'react-router-dom';
import { setQuestionnaireValues, fetchPrices } from './questionnaire-actions';
import Slider from '../../common/forms/Slider';
import Range from '../../common/forms/Range';
import { connect as formikConnect } from 'formik';
import LocalizedStrings from 'react-localization';
import MultiButtonField from '../../common/forms/MultiButtonField';
import GolfBallTeeIcon from '../../common/components/icons/GolfBallTee';
import CashIcon from '../../common/components/icons/Cash';
import ArrowRight from '../../common/components/icons/ArrowRight';
import ArrowLeft from '../../common/components/icons/ArrowLeft';
import clamp from '../../common/utils/clamp';
import Toggle from 'react-toggle';

const strings = new LocalizedStrings({
  en: {
    title: 'Ballnamic',
    finishingTouches: 'Finishing Touches',
    toggleText: 'Do you care about how firm or soft your golf ball feels?',
    putterFeelPreference: 'Putter Feel Preference',
    verySoft: 'Very Soft',
    soft: 'Soft',
    medium: 'Medium',
    firm: 'Firm',
    veryFirm: 'Very Firm',
    pricePreference: 'Price Preference',
    putterFeelSubtext: `When answering this question, trust your instinct - it’s all about your personal preference. If you use a putter with a soft face or deep grooves, selecting a firmer preference here will give you a ball with more feedback. If you use a solid face putter and prefer a more forgiving feel, select a softer preference.`,
    priceDataIsGatheredFrom: `Price data is gathered from publicly available sources, is subject to change without our knowledge, and only intended to be a general guide to the ball fitting process.`,
    priceRangeLabel: 'Select Desired Price Range',
  },
});

export const putterFeelLabelMap = {
  0: strings.verySoft,
  1: strings.soft,
  2: strings.medium,
  3: strings.firm,
  4: strings.veryFirm,
};

export const COST_MIN = 0;
export const COST_MAX = 2;

class FinishingTouches extends Component {
  componentDidMount() {
    //window.analytics.page("Finishing Touches");
    const { dispatch, formik: { values, setFieldValue } } = this.props;

    dispatch(fetchPrices());
    
    // Apply default values
    import('./applyDefaultValues').then(module => {
      const applyDefaultValues = module.default;
      applyDefaultValues(values, setFieldValue);
    });
  }

  componentDidUpdate(prevProps) {
    const { setFieldValue } = this.props.formik;
    if (prevProps.loggedInUser.get('six_iron_fitting') !== this.props.loggedInUser.get('six_iron_fitting')) {
      setFieldValue('iron_distance', 145);
      setFieldValue('iron_spin_type', 'mid');
      setFieldValue('iron_launch_type', 'mid');
    }
  }

  renderPricePreferenceFields() {
    const {
      loggedInUser,
      formik: { values, setFieldValue },
    } = this.props;

    if (!loggedInUser.get('show_price_preference')) {
      return null;
    }

    return (
      <React.Fragment>
        <MultiButtonField
          choices={[
            {
              icon: <GolfBallTeeIcon size={32} />,
              label: `I want the best ball possible for my game`,
              value: 'none',
            },
            {
              icon: <CashIcon size={32} />,
              label: `I want the best matched ball for its price`,
              value: 'strict',
            },
          ]}
          value={values.cost_preference}
          onChange={setFieldValue.bind(this, 'cost_preference')}
          label={strings.pricePreference}
        />
        {this.renderPriceRangeSlider()}
      </React.Fragment>
    );
  }

  renderPriceRangeSlider() {
    const {
      formik: { values, setFieldValue },
    } = this.props;

    if (values.cost_preference === 'none') {
      return null;
    }

    const marks = {
      0: <div className="slider--mark text">$</div>,
      1: <div className="slider--mark text">$$</div>,
      2: <div className="slider--mark text">$$$</div>,
    };

    // Clamping values in case out-of-range values from a previous version are still in state
    const valueToDollarSigns = value =>
      Array(clamp(value, COST_MIN, COST_MAX) + 1)
        .fill('$')
        .join('');

    return (
      <Range
        subText={strings.priceDataIsGatheredFrom}
        displayValue={`${valueToDollarSigns(values.cost_lower_limit)} - ${valueToDollarSigns(values.cost_upper_limit)}`}
        textMarks
        label={strings.priceRangeLabel}
        sliderProps={{
          value: [
            clamp(values.cost_lower_limit, COST_MIN, COST_MAX),
            clamp(values.cost_upper_limit, COST_MIN, COST_MAX),
          ],
          min: COST_MIN,
          max: COST_MAX,
          marks,
          onChange: values => {
            setFieldValue('cost_lower_limit', Math.min.apply(Math, values));
            setFieldValue('cost_upper_limit', Math.max.apply(Math, values));
          },
        }}
      />
    );
  }

  renderToggle() {
    const {
      formik: { values, setFieldValue },
    } = this.props;
    return (
      <div className="toggle--container">
        <label>
          <span className="toggle-label">{strings.toggleText}</span>
          <Toggle
            checked={values.ball_firmness_matters}
            icons={false}
            onChange={event => setFieldValue('ball_firmness_matters', !values.ball_firmness_matters)}
            aria-selected={values.putter_feel_pref}
            aria-label={strings.toggleText}
          />
        </label>
      </div>
    );
  }

  renderPutterFeelPreference() {
    const {
      formik: { values, setFieldValue },
    } = this.props;

    if (!values.ball_firmness_matters) {
      return null;
    }

    return (
      <Slider
        label={strings.putterFeelPreference}
        subLabel={strings.putterFeelSubtext}
        displayValue={putterFeelLabelMap[values.putter_feel_pref]}
        sliderProps={{
          min: 0,
          max: 4,
          step: 1,
          marks: {
            0: <div className="slider--mark text">{putterFeelLabelMap[0]}</div>,
            4: <div className="slider--mark text">{putterFeelLabelMap[4]}</div>,
          },
          dots: true,
          value: values.putter_feel_pref,
          onChange: value => setFieldValue('putter_feel_pref', value),
        }}
      />
    );
  }
  segment_touches_post(){
    const { formik : { values }} = this.props;
    let eventData = {
      ball_feel_preference: values.ball_firmness_matters,
      putter_feel_preference: values.ball_firmness_matters ? putterFeelLabelMap[values.putter_feel_pref] : null,
      price_preference: values.cost_preference === 'strict' ? "Best Ball for Price" : "Best Ball for Game",
      fitting_id: null
    };
    if (values.cost_preference === 'strict') {
      eventData.price_lower = values.cost_lower_limit + 1;
      eventData.price_higher = values.cost_upper_limit + 1;
    }
    window.analytics.track("Finishing Touches", eventData);
    window.gtag('event', 'Finishing Touches', eventData);
  }
  render() {
    const {
      dispatch,
      history,
      formik: { values },
    } = this.props;

    return (
      <div className="questionnaire__wrapper">
        <Helmet>
          <title>{`${strings.finishingTouches} - ${strings.title}`}</title>
        </Helmet>
        <div className="questionnaire--inner">
          <div className="questionnaire--header">
            <h1>{strings.finishingTouches}</h1>
          </div>
          <div className="questionnaire-blocks__container">
            {this.renderToggle()}
            {this.renderPutterFeelPreference()}
            {this.renderPricePreferenceFields()}
          </div>
          <div className="questionnaire--button__wrapper">
            <button
              className="button button--color--primary button--theme--outline button--icon"
              type="button"
              onClick={() => {
                dispatch(setQuestionnaireValues(values));

                this.segment_touches_post();
                history.push('/my-fitting/wedges');
              }}
            >
              <ArrowLeft />
              <div>Back</div>
            </button>
            <button
              className="button button--color--primary button--icon"
              type="button"
              onClick={() => {
                dispatch(setQuestionnaireValues(values));
                this.segment_touches_post();
                history.replace('/my-fitting/results');
              }}
            >
              <div>Next</div>
              <ArrowRight />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  formikConnect,
  connect((state, ownProps) => ({
    loggedInUser: state.auth.loggedInUser,
  })),
  withRouter
)(FinishingTouches);
