import { combineReducers } from 'redux';
import Immutable from 'immutable';
import localstorage from 'store2';
import getApiReducer, { defaultState } from '../../common/utils/getApiReducer';
import clamp from '../../common/utils/clamp';
import roundToNearestStep from '../../common/utils/roundToNearestStep';
import {
  TEMPERATURE_MIN,
  TEMPERATURE_MAX,
  TEMPERATURE_STEP,
  ALTITUDE_MIN,
  ALTITUDE_MAX,
  ALTITUDE_STEP,
} from '../fitting-constants';

import {
  SET_QUESTIONNAIRE_VALUES,
  FETCH_BRANDS,
  FETCH_MODELS,
  FETCH_YEARS,
  RESET_MODELS,
  RESET_YEARS,
  CALCULATE_FLIGHTS,
  CALCULATE_DRIVER_LAUNCH_CONDITIONS,
  CALCULATE_IRON_LAUNCH_CONDITIONS,
  FETCH_PRICES,
  FETCH_RESULTS,
  RESET_QUESTIONNAIRE,
  CALCULATE_RELATIVE_RESULTS,
  RESET_RELATIVE_RESULTS,
  SET_RECIPIENTS,
  SEND_RESULTS,
  RESET_SEND_RESULTS,
  FETCH_STATIC_RESULTS,
  LOOK_UP_TEMPERATURE_AND_ALTITUDE,
  CALCULATE_DRIVER_HEIGHT_PREFERENCE,
  CALCULATE_IRON_STOPPING_PREFERENCE,
  RESET_CALCULATED_DRIVER_HEIGHT,
  RESET_CALCULATED_IRON_STOP,
  ACCEPT_DISCLAIMER,
  IRON_LAUNCH_CONDITIONS_KNOWN,
  DRIVER_LAUNCH_CONDITIONS_KNOWN,
  GET_RESULT_TEASER,
} from './questionnaire-action-constants';

export const questionnaireDefaults = {
  handicap: 'scratch-or-better',
  brand: '',
  model: '',
  year: '',
  typical_temp: 80,
  typical_alt: 1000,
  driver_ball_speed: 140,
  driver_launch_angle: 12,
  driver_spin_rate: 2600,
  driver_height_pref: 2,
  driver_wind_imp: 1,
  driver_distance: 225,
  driver_spin_type: 'mid',
  driver_launch_type: 'mid',
  iron_ball_speed: 105,
  iron_launch_angle: 17.5,
  iron_spin_rate: 6250,
  iron_stop_pref: 2,
  iron_workability_pref: 2,
  iron_flier_imp: 1,
  iron_distance: 145,
  iron_spin_type: 'mid',
  iron_launch_type: 'mid',
  wedge_spin_pref: 2,
  greenside_spin_imp: 1,
  ball_firmness_matters: true,
  putter_feel_pref: 2,
  cost_preference: 'none',
  cost_lower_limit: 0,
  cost_upper_limit: 2,
  driver_launch_conditions_known: null,
  iron_launch_conditions_known: null,
  driver_height_pref_calc: null,
  iron_stop_pref_calc: null,
};

const defaultQuestionnaireValues = Immutable.fromJS(
  localstorage.get('questionnaire')
    ? Object.assign({}, questionnaireDefaults, localstorage.get('questionnaire'))
    : questionnaireDefaults
);

const defaultRecipients = Immutable.fromJS(localstorage.get('recipients') || null);

export default combineReducers({
  calculateDriverLaunchConditions: getApiReducer(CALCULATE_DRIVER_LAUNCH_CONDITIONS),
  calculateIronLaunchConditions: getApiReducer(CALCULATE_IRON_LAUNCH_CONDITIONS),
  fetchResults: getApiReducer(FETCH_RESULTS),
  fetchStaticResults: getApiReducer(FETCH_STATIC_RESULTS),
  calculateFlights: getApiReducer(CALCULATE_FLIGHTS),
  lookupTemperatureAndAltitude: getApiReducer(LOOK_UP_TEMPERATURE_AND_ALTITUDE),
  calculateDriverHeightPreference: getApiReducer(CALCULATE_DRIVER_HEIGHT_PREFERENCE),
  calculateIronStoppingPreference: getApiReducer(CALCULATE_IRON_STOPPING_PREFERENCE),
  getResultTeaser: getApiReducer(GET_RESULT_TEASER),
  sendResults: (state = defaultState, action) => {
    let newState = getApiReducer(SEND_RESULTS)(state, action);
    if (action.type === RESET_SEND_RESULTS) {
      return defaultState;
    }
    return newState;
  },
  calculatedTemperatureAndAltitude: (state = null, action) => {
    if (action.type === `${LOOK_UP_TEMPERATURE_AND_ALTITUDE}_SUCCESS`) {
      return Immutable.fromJS({
        typical_alt: roundToNearestStep(
          clamp(action.json.elevation_exact_ft, ALTITUDE_MIN, ALTITUDE_MAX),
          ALTITUDE_STEP
        ),
        typical_temp: roundToNearestStep(
          clamp(action.json.mean_temp_exact, TEMPERATURE_MIN, TEMPERATURE_MAX),
          TEMPERATURE_STEP
        ),
      });
    }
    return state;
  },
  calculatedDriverHeight: (state = null, action) => {
    if (action.type === `${CALCULATE_DRIVER_HEIGHT_PREFERENCE}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    if (
      action.type === RESET_CALCULATED_DRIVER_HEIGHT ||
      action.type === `${LOOK_UP_TEMPERATURE_AND_ALTITUDE}_SUCCESS`
    ) {
      return null;
    }
    return state;
  },
  calculatedIronStop: (state = null, action) => {
    if (action.type === `${CALCULATE_IRON_STOPPING_PREFERENCE}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    if (action.type === RESET_CALCULATED_IRON_STOP || action.type === `${LOOK_UP_TEMPERATURE_AND_ALTITUDE}_SUCCESS`) {
      return null;
    }
    return state;
  },
  staticResults: (state = null, action) => {
    if (action.type === `${FETCH_STATIC_RESULTS}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    return state;
  },
  recipients: (state = defaultRecipients, action) => {
    if (action.type === SET_RECIPIENTS) {
      const newState = Immutable.fromJS(action.recipients);
      localstorage.set('recipients', newState.toJS());
      return newState;
    }
    if (action.type === RESET_QUESTIONNAIRE) {
      localstorage.set('recipients', null);
      return null;
    }
    return state;
  },
  brands: (state = null, action) => {
    if (action.type === `${FETCH_BRANDS}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    return state;
  },
  models: (state = null, action) => {
    if (action.type === `${FETCH_MODELS}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    if (action.type === RESET_MODELS) {
      return null;
    }
    return state;
  },
  years: (state = null, action) => {
    if (action.type === `${FETCH_YEARS}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    if (action.type === RESET_YEARS) {
      return null;
    }
    return state;
  },
  prices: (state = null, action) => {
    if (action.type === `${FETCH_PRICES}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    return state;
  },
  values: (state = defaultQuestionnaireValues, action) => {
    if (action.type === SET_QUESTIONNAIRE_VALUES) {
      const newState = state.merge(action.values);
      localstorage.set('questionnaire', newState.toJS());
      return newState;
    }
    if (action.type === IRON_LAUNCH_CONDITIONS_KNOWN) {
      const newState = state.merge({
        iron_launch_conditions_known: action.known,
      });
      localstorage.set('questionnaire', newState.toJS());
      return newState;
    }
    if (action.type === DRIVER_LAUNCH_CONDITIONS_KNOWN) {
      const newState = state.merge({
        driver_launch_conditions_known: action.known,
      });
      localstorage.set('questionnaire', newState.toJS());
      return newState;
    }
    if (
      action.type === `${CALCULATE_DRIVER_LAUNCH_CONDITIONS}_SUCCESS` ||
      action.type === `${CALCULATE_IRON_LAUNCH_CONDITIONS}_SUCCESS`
    ) {
      const newState = state.merge(action.json[0]);
      localstorage.set('questionnaire', newState.toJS());
      return newState;
    }
    if (action.type === RESET_QUESTIONNAIRE) {
      localstorage.set('questionnaire', Immutable.fromJS(questionnaireDefaults));
      return Immutable.fromJS(questionnaireDefaults);
    }
    return state;
  },
  results: (state = null, action) => {
    if (action.type === `${FETCH_RESULTS}_SUCCESS`) {
      return Immutable.fromJS(
        Object.assign(
          {
            valuesHash: action.valuesHash,
          },
          action.json
        )
      );
    }
    if (action.type === RESET_QUESTIONNAIRE) {
      return null;
    }
    return state;
  },
  relativeResults: (state = null, action) => {
    if (action.type === `${CALCULATE_RELATIVE_RESULTS}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    if (action.type === RESET_RELATIVE_RESULTS) {
      return null;
    }
    return state;
  },
  flights: (state = null, action) => {
    if (action.type === `${CALCULATE_FLIGHTS}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    return state;
  },
  hasAcceptedDisclaimer: (state = false, action) => {
    switch (action.type) {
      case RESET_QUESTIONNAIRE:
        return false;
      case ACCEPT_DISCLAIMER:
        return true;
      default:
        return state;
    }
  },
  resultTeaser: (state = false, action) => {
    if (action.type === `${GET_RESULT_TEASER}_SUCCESS`) {
      return Immutable.fromJS(action.json);
    }
    return state;
  },
});
