import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import { useDispatch, useSelector } from 'react-redux';
import LandingPageHeader from './LandingPageHeader';
import Footer from '../common/components/Footer';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { fetchLoggedInUser } from '../auth/auth-actions';
import { fetchPrices } from '../payment/payment-actions';
import { getMyFittingCodesList } from '../user/user-actions';
import GolfDigest from './images/review/GolfDigest.png';
import GolfDotCom from './images/review/GolfDotCom.png';
import MyGolfSpy from './images/review/MyGolfSpy.png';
import { PurchaseFormContext } from '../context/PurchaseFormContext';

import LoadingIndicator from '../common/components/LoadingIndicator';
import ScrollToTop from '../common/components/ScrollToTop';
import Announcement from './Announcement';

import ComprehensiveFitting from './images/guesswork/ComprehensiveFitting.png';
import CheckIcon from './images/pricing/check.png';
import TourLevel from './images/guesswork/Tourlevel.png';
import LaunchConditions from './images/guesswork/LaunchConditions.svg';
import FinishingTouches from './images/guesswork/FinishingTouches.svg';
import TeeToGreen from './images/guesswork/TeeToGreen.svg';
import DataDriven from './images/guesswork/DataDriven.svg';

import VimeoEmbed from '../common/components/VimeoEmbed';

import Callaway from './images/topmatches/callaway.png';
import Srixon from './images/topmatches/srixon.png';
import Bfour from './images/topmatches/bfour.png';

const strings = new LocalizedStrings({
  en: {
    login: 'Login',
    registration: 'Register',
    library: 'Library',
    startMyFitting: 'Start My Fitting',
    getStarted: 'Get Started',
    getFit: 'Buy Now',
    RedeemMyCode: 'Redeem My Code',
    reviewedAndTestedBy: 'REVIEWED AND TESTED BY',
    learnMore: 'Learn More',
    findBallTitle: 'Take Away the Guesswork',
    comprehensiveFitting: `Tee-to-Green Fitting Experience`,
    comprehensiveFittingDescription: 'Our fitting process evaluates every aspect of your game. You’ll enter information from your driver launch conditions down to how you like the ball to feel off your putter.',
    buyMoreCodes: 'Buy More Fittings',
    tourLevelTechnology: 'Industry-Leading Fitting Technology',
    tourLevelTechnologyDescription: 'Every ball in our database undergoes hundreds of test shots on PING’s state-of-the-art swing robot, and the data is analyzed by PING’s Fitting Scientists.',

    dataDrivenResults: 'Data-Driven Fitting Results',
    dataDrivenResultsDescription: 'Your results are packed with detailed performance insights that includes an overall match percentage for each ball and ball flight projections.',
    buildYourProfile: 'Build Your Profile',
    RedeemCode: 'REDEEM CODE',
    AlreadyHaveaCode: 'Already Have a Code?',
    RedeemYourFitting: 'Redeem your fitting code and find your fit.',
    getTopMatches: 'Results in Minutes',
    getTopMatchesDescription: 'Within a few minutes you’ll gain access to results that are sorted by a match percentage and include flight charts and performance insights for each ball that inclue an overall match percentage and flight charts.',

    match: 'MATCH',
    idealStoppingPower: 'IDEAL STOPPING POWER',
    topDriverDistance: 'TOP DRIVER DISTANCE',
    idealWorkability: 'IDEAL WORKABILITY',
    idealStoppingPowerPercentage: '95.1%',
    topDriverDistancePercentage: '97.0%',
    idealWorkabilityPercentage: '94.9%',
    chromeSoftX: 'CHROME SOFT X',
    tourBX: 'TOUR B X',
    zStarXV: 'Z STAR XV',

    whatsToExpect: 'What to Expect',
    dataDrivenBallRecommendationsDescription: 'Enter some information about the current ball you play and your typical playing location. ',

    performanceInsightsForEachBall: 'Enter Launch Conditions',
    performanceInsightsForEachBallDescription: 'Provide your driver and 7-iron launch conditions for us to analyze. You can either input your exact launch conditions or we can help make an estimate.',

    chooseAPackageOfFitting: 'Finishing Touches',
    chooseAPackageOfFittingDescription: 'Answer a few questions about how much spin you like to play with your wedges and how you like the ball to feel around the greens.',

    aCloserLookAtBallnamic: 'A Closer Look at Ballnamic',

    substantialTesting: 'Substantial Testing',
    substantialTestingDescription: 'Ballnamic is built upon a frequently updated database of over 60 ball models. ',
    databaseAndFlightModels: 'In order to account for all playing and launch conditions, each ball undergoes hundreds of test shots with a state-of-the-art swing \n robot and a TrackMan Launch Monitor.',

    tellUsAboutYourGame: 'Tell Us About Your Game',
    tellUsAboutYourGameDescription: 'You will be guided to think about balls with the intelligence and depth of a Tour player. Entering nuanced information about yourself and where you play, from trajectory information to feel preference around the greens, will drive the Ballnamic fitting algorithms.',

    swingAnalysis: 'Swing Analysis',
    swingAnalysisDescription: 'We\'ll run your inputs against our ball database that is powered by cutting-edge algorithms and thousands of test shots, which evaluate and estimate ball performance in various conditions.',

    actionableResults: 'Actionable Results',
    actionableResultsDescription: 'Receive four ball recommendations with detailed performance insights on workability, wind stability, and spin generation, as well as flight model projections for full down range driver and iron trajectories.',
    actionableResultsDescription2: 'You’ll have access to these results for ____.',

    ballnamicPricing: 'BALLNAMIC PRICING',
    findTheRightPackageForYou: 'Find the Right Package for You',

    fiveFittings: '5 Fittings',
    matchPercentageThatIdentifiesHowWellABallMatchesYourGame: 'A match percentage that identifies how well a ball matches your game',

    performanceMetricsForEachBall: 'Performance metrics for each ball',
    projectedFlightChartsToCompareBallPerformance: 'Projected flight charts to compare ball performance',
    fittingsForYouToUseOrShareWithOthers: ' for you to use or share with others',

    threeFittings: '3 Fittings',

    oneFitting: '1 Fitting',
    fiveDataDrivenBallRecommendations: '5 Data-Driven Ball Recommendations',

    howItWorks: 'How Ballnamic Works',
    purchaseYourBallnamicFittingPackage: 'Purchase your Ballnamic Fitting package.',
    registerForAnAccountOrLoginToRedeemYourFittingCode: ' Register for an account or login to redeem your fitting code.',
    completeTheFittingFormAndUnlockGameChangingInsights: 'Complete the fitting form and unlock game-changing insights.',

    iFoundThisToolVeryHelpfulInNarrowingDownWhatGolfBallsIWillTestAndGivingMeConfidenceThatIMUsingTheRightOne: 'I FOUND THIS TOOL VERY HELPFUL IN NARROWING DOWN WHAT GOLF BALLS I WILL TEST AND GIVING ME CONFIDENCE THAT I\'M USING THE RIGHT ONE.',
    independentGolfReviews: 'Independent Golf Reviews',


    poweredBy: {
      heading: 'Powered by Tour-Level Technology',
      subText:
        "Ballnamic is built upon a database of over 60 ball models, and it's frequently being updated. In order to account for all playing conditions and launch angles, each ball undergoes hundreds of test shots with a state-of-the-art swing robot and a TrackMan Launch Monitor. The data is compiled and analyzed by PING's team of data scientists.",
    },
    customContact: 'Custom fitter inquiries can be submitted to {0}',
    copyright:
      '{0} Copyright 2020 PING Inc. All rights reserved. Ballnamic is a trademark of Karsten Manufacturing Corporation in the U.S. and other countries. All third-party trademarks are the property of their respective owners, and use of such trademarks does not imply any affiliation with or endorsement by them.',
  },
});

const LandingPage = () => {
  const dispatch = useDispatch();
  const { handlePurchaseForm } = useContext(PurchaseFormContext);
  
  const token = useSelector(state => state.auth.token);
  const loggedInUser = useSelector(state => state.auth.loggedInUser);
  const fetchingLoggedInUser = useSelector(state => state.auth.fetchLoggedInUser.get('loading'));
  const prices = useSelector(state => state.payment.prices);
  const fetchingPrices = useSelector(state => state.payment.fetchPrices.get('loading'));
  const myFittingCodesList = useSelector(state => state.user.myFittingCodesList);

  useEffect(() => {
    window.analytics.page('Landing Page');
    if (token && !loggedInUser) {
      dispatch(fetchLoggedInUser());
    }
    if (token && loggedInUser) {
      dispatch(getMyFittingCodesList({email: loggedInUser.getIn(['oidcData', 'email'])}));
    }
    dispatch(fetchPrices());

    window.addEventListener('load', priceScroll);
    window.addEventListener('resize', priceScroll);
    window.addEventListener('scroll', toggleScrollBtn);

    return () => {
      window.removeEventListener('load', priceScroll);
      window.removeEventListener('resize', priceScroll);
      window.removeEventListener('scroll', toggleScrollBtn);
    };
  }, [dispatch, token, loggedInUser]);

  const toggleScrollBtn = () => {
    try {
      const scrollButton = document.getElementById('scroll-to-top');
      if (scrollButton) {
        const top = window.scrollY;
        scrollButton.style.display = top <= 254 ? 'none' : 'flex';
      }
    } catch (error) {
      console.warn('Error in toggleScrollBtn:', error);
    }
  };

  const priceScroll = () => {
    const priceOptions = document.getElementById('price-options');
    if (priceOptions) {
      priceOptions.scrollLeft = (980 - window.outerWidth) / 2;
    }
  };

  const scrollToElement = (elementId) => {
    document.getElementById(elementId)?.scrollIntoView({ behavior: 'smooth' });
  };

  if (fetchingLoggedInUser || fetchingPrices || !prices) {
    return <LoadingIndicator />;
  }

  return (
    <ScrollToTop>
      <div className="ballnamic--landing-page">
        <script src="https://player.vimeo.com/api/player.js"></script>
        <Announcement />
        
        <LandingPageHeader 
          isLoggedIn={!!loggedInUser} 
          loggedInUser={loggedInUser} 
          scrollBtn={() => scrollToElement('pricing')}
        />

        <div id="hero">
          <h1>The Most Comprehensive Online Ball Fitting Tool in Golf.</h1>
          <VimeoEmbed id='1036522537' />          
          <div className='button-startlifting-learnmore'>
            {!loggedInUser ? (
              <button 
                className="button button--color--primary"
                onClick={() => scrollToElement('pricing')}
              >
                {strings.getStarted}
              </button>
            ) : (
              <Link 
                className="button button--color--primary" 
                to={myFittingCodesList?.size === 0 ? "/redeem" : "/profile/my-fitting-codes"}
                onClick={() => {
                  localStorage.setItem('selectedFitting', 'premium');
                }}
              >
                {strings.startMyFitting}
              </Link>
            )}
          </div>
        </div>
        <div className="main-content">
          <div id="find-ball">
            <h2>{strings.findBallTitle}</h2>
            <div className="balls-list">
              <div className="ball-details">
                <img src={TeeToGreen} alt="Data Driven Ball Recommendations" />
                <h3>{strings.comprehensiveFitting}</h3>
                <p>{strings.comprehensiveFittingDescription}</p>
              </div>

              <div className="ball-details">
                <img src={ComprehensiveFitting} alt="Performance Insights" />
                <h3>{strings.tourLevelTechnology}</h3>
                <p>{strings.tourLevelTechnologyDescription}</p>
              </div>

              <div className="ball-details">
                <img src={DataDriven} alt="5 Fittings" />
                <h3>{strings.dataDrivenResults}</h3>
                <p>{strings.dataDrivenResultsDescription}</p>
              </div>
            </div>
          </div>
          <div id='review-tested-by'>
                <div>
                  <h2>{strings.reviewedAndTestedBy}</h2>
                  <div className='review-list'>
                    <img src={MyGolfSpy} alt="Club Up Golf" className='vertical' />
                    <img src={GolfDotCom} alt="Golf Digest" />
                    <img src={GolfDigest} alt="Golf Dot Com" />
                  </div>
                </div>
          </div>
          <div id="pricing">
            <div className="lines">
              <div></div>
              <div></div>
              <div></div>
            </div>

            <h2>{strings.ballnamicPricing}</h2>
            <h3>{strings.findTheRightPackageForYou}</h3>

            <div className="pricing-options" id="price-options">
              <div className="pricing-option">
                <p><div className="fake-button">5 Fittings</div></p>
                <h4>${(prices?.premium) / 100}</h4>
                <ul>
                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                    {strings.fiveDataDrivenBallRecommendations}
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                    {strings.matchPercentageThatIdentifiesHowWellABallMatchesYourGame}
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                    {strings.performanceMetricsForEachBall}
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                    {strings.projectedFlightChartsToCompareBallPerformance}
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                    <span className="lif">{strings.fiveFittings + strings.fittingsForYouToUseOrShareWithOthers}</span>
                  </li>
                </ul>
                <button className="button button--color--primary button--theme--primary" onClick={() => handlePurchaseForm('premium')}>
                  {strings.getFit}
                </button>
              </div>

              <div className="pricing-option best-seller">
                <p>
                  <div className="fake-button">{strings.threeFittings}</div>
                </p>
                <h4>${(prices?.standard) / 100}</h4>
                <ul>
                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                    {strings.fiveDataDrivenBallRecommendations}
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                    {strings.matchPercentageThatIdentifiesHowWellABallMatchesYourGame}
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                    {strings.performanceMetricsForEachBall}
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                    {strings.projectedFlightChartsToCompareBallPerformance}
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                    <span className="lif">{strings.threeFittings + strings.fittingsForYouToUseOrShareWithOthers}</span>
                  </li>
                </ul>
                <button className="button button--color--primary button--theme--primary" onClick={() => handlePurchaseForm('standard')}>
                  {strings.getFit}
                </button>
              </div>

              <div className="pricing-option">
                <p><div className="fake-button">{strings.oneFitting}</div></p>
                <h4>${(prices?.basic) / 100}</h4>
                <ul>
                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                    {strings.fiveDataDrivenBallRecommendations}
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                    {strings.matchPercentageThatIdentifiesHowWellABallMatchesYourGame}
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                    {strings.performanceMetricsForEachBall}
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                    {strings.projectedFlightChartsToCompareBallPerformance}
                  </li>
                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={CheckIcon} alt="Check Icon" className="check-icon" />
                    <span className="lif">{strings.oneFitting + strings.fittingsForYouToUseOrShareWithOthers}</span>
                  </li>
                </ul>
                <button className="button button--color--primary button--theme--primary" onClick={() => handlePurchaseForm('basic')}>
                  {strings.getFit}
                </button>
              </div>
            </div>
          </div>

          <div id='review-tested'>
            <div>
              <div className='review-list'>
                <h1>{strings.AlreadyHaveaCode}</h1>
                <h2>{strings.RedeemYourFitting}</h2>
                <div className='button-startlifting'>
                  {!loggedInUser ? (
                    <Link 
                      className="button button--color--white"
                      to="/redeem"
                      onClick={() => {
                        localStorage.setItem('selectedFitting', 'premium');
                      }}
                    >
                      {strings.buyMoreCodes}
                    </Link>
                  ) : (
                    <Link 
                      className="button button--color--white" 
                      to={myFittingCodesList?.size === 0 ? "/redeem" : "/profile/my-fitting-codes"}
                      onClick={() => {
                        localStorage.setItem('selectedFitting', 'premium');
                      }}
                    >
                      {strings.RedeemMyCode}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div id="how-it-works">
            <div>
              <h2> {strings.howItWorks}</h2>
              <div className="steps">
                <div className="steps-square">
                  <h3>1</h3>
                </div>
                <p>{strings.purchaseYourBallnamicFittingPackage}</p>
              </div>

              <div className="steps">
              <div className="steps-square">
                <h3>2</h3>
              </div>
                <p>{strings.registerForAnAccountOrLoginToRedeemYourFittingCode}</p>
              </div>

              <div className="steps">
              <div className="steps-square">
                <h3>3</h3>
              </div>
                <p>{strings.completeTheFittingFormAndUnlockGameChangingInsights}</p>
              </div>
            </div>
          </div>
          <div id="top-matches">
            <h2>{strings.getTopMatches}</h2>
            <p>{strings.getTopMatchesDescription}</p>

            <div className="top-matches-list">
              <div className="top-match">
                <p>{strings.idealWorkability}</p>
                <h4>{strings.idealWorkabilityPercentage}</h4>
                <h3>{strings.match}</h3>
                <img src={Callaway} alt="Data Driven Ball Recommendations" />
                <h2>{strings.chromeSoftX}</h2>
              </div>

              <div className="top-match">
                <p>{strings.topDriverDistance}</p>
                <h4>{strings.topDriverDistancePercentage}</h4>
                <h3>{strings.match}</h3>
                <img src={Srixon} alt="Performance Insights" />
                <h2>{strings.zStarXV}</h2>
              </div>

              <div className="top-match">
                <p>{strings.idealStoppingPower}</p>
                <h4>{strings.idealStoppingPowerPercentage}</h4>
                <h3>{strings.match}</h3>
                <img src={Bfour} alt="5 Fittings" />
                <h2>{strings.tourBX}</h2>
              </div>
            </div>
          </div>

          <div id="whats-included">
            <h2>{strings.whatsToExpect}</h2>
            <div className="features">
              <div className="feature">
                <img src={TourLevel} alt="Data Driven Ball Recommendations" />
                <h3>{strings.buildYourProfile}</h3>
                <p>{strings.dataDrivenBallRecommendationsDescription}</p>
              </div>

              <div className="feature">
                <img src={LaunchConditions} alt="Performance Insights" />
                <h3>{strings.performanceInsightsForEachBall}</h3>
                <p>{strings.performanceInsightsForEachBallDescription}</p>
              </div>

              <div className="feature">
                <img src={FinishingTouches} alt="5 Fittings" />
                <h3>{strings.chooseAPackageOfFitting}</h3>
                <p>{strings.chooseAPackageOfFittingDescription}</p>
              </div>
            </div>
          </div>
          <div id="closer-look">
            <h2>{strings.aCloserLookAtBallnamic}</h2>
            <div className="videos-list">
              <div className="video-item">
                <div className='item-text'>
                  <p>{strings.substantialTestingDescription}</p>
                  <p>{strings.databaseAndFlightModels}</p>
                </div>
                <div className='item-video'>
                  <VimeoEmbed id='1029743580' />
                </div>
              </div>
              <div className="button-startlifting">
                {!loggedInUser ? (
                  <button 
                    className="button button--color--primary button--rounded"
                    onClick={() => scrollToElement('pricing')}
                  >
                    {strings.getStarted}
                  </button>
                ) : (
                  <Link 
                    className="button button--color--primary button--rounded" 
                    to={myFittingCodesList?.size === 0 ? "/redeem" : "/profile/my-fitting-codes"}
                    onClick={() => {
                      localStorage.setItem('selectedFitting', 'premium');
                    }}
                  >
                    {strings.getStarted}
                  </Link>
                )}
              </div>
            </div>
          </div>
          <button
            id='scroll-to-top'
            className="scroll-to-top"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
            ↑
          </button>
        </div>
        <Footer />
      </div>
    </ScrollToTop>
  );
};

export default LandingPage;
