import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/flowRight';
import LocalizedStrings from 'react-localization';
import { connect as formikConnect } from 'formik';
import { setQuestionnaireValues } from './questionnaire-actions';
import classNames from 'classnames';
import { calculateLaunchConditionsIfNeeded, validateProfileStep } from './utils';
//import {strings, heightPrefLabelMap, windConditionsLabelMap} from './DriverDetails';

let strings = new LocalizedStrings({
  en: {
    yourProfile: 'Your Profile',
    driver: 'Driver',
    irons: 'Irons',
    wedges: 'Wedges',
    finishingTouches: `Finishing\nTouches`,
    results: 'Confirm Inputs',
    lowTrajectory: 'Lowest\nTrajectory',
    lowMidTrajectory: 'Low-Mid\nTrajectory',
    midTrajectory: 'Mid Trajectory',
    midHighTrajectory: 'Mid-High Trajectory',
    highTrajectory: 'Highest\nTrajectory',
    windPerformanceSliderMinimum: 'Not Important',
    windPerformanceSliderModerate: 'Moderate Importance',
    windPerformanceSliderMaximum: 'High Importance',
    lowest: 'Shallow\nLanding\nAngle',
    belowAverage: 'Moderately Shallow\nLanding Angle',
    average: 'Neutral Landing Angle',
    aboveAverage: 'Moderately Steep\nLanding Angle',
    highest: 'Steep\nLanding\nAngle',
    leastCurve: 'Least Curve',
    someWorkability: 'Some Workability',
    mostWorkability: 'Most Workability',
    flierMinimum: 'Not\nImportant',
    flierModerate: 'Moderate Importance',
    flierMaximum: 'High\nImportance',
    leastSpin: 'Least Spin',
    lowSpin: 'Low Spin',
    moderateSpin: 'Moderate Spin',
    highSpin: 'High Spin',
    maxSpin: 'Maximum Spin',
    greensideSpinMaximum: `High Importance`,
    greensideSpinModerate: `Moderate Importance`,
    greensideSpinMinimum: `Not Important`,
    verySoft: 'Very Soft',
    soft: 'Soft',
    medium: 'Medium',
    firm: 'Firm',
    veryFirm: 'Very Firm',
  },
});
export const putterFeelLabelMap = {
  0: strings.verySoft,
  1: strings.soft,
  2: strings.medium,
  3: strings.firm,
  4: strings.veryFirm,
};
const spinPreferenceLabelMap = {
  0: strings.leastSpin,
  1: strings.lowSpin,
  2: strings.moderateSpin,
  3: strings.highSpin,
  4: strings.maxSpin,
};
const greenSpinPreferenceMap = {
  0: strings.greensideSpinMinimum,
  1: strings.greensideSpinModerate,
  2: strings.greensideSpinMaximum,
};
const stoppingPowerLabelMap = {
  0: strings.lowest,
  1: strings.belowAverage,
  2: strings.average,
  3: strings.aboveAverage,
  4: strings.highest,
};

const workabilityLabelMap = {
  0: strings.leastCurve,
  2: strings.someWorkability,
  4: strings.mostWorkability,
};

const flierPreventionLabelMap = {
  0: strings.flierMinimum,
  1: strings.flierModerate,
  2: strings.flierMaximum,
};
const heightPrefLabelMap = {
  0: strings.lowTrajectory,
  1: strings.lowMidTrajectory,
  2: strings.midTrajectory,
  3: strings.midHighTrajectory,
  4: strings.highTrajectory,
};

const windConditionsLabelMap = {
  0: strings.windPerformanceSliderMinimum,
  1: strings.windPerformanceSliderModerate,
  2: strings.windPerformanceSliderMaximum,
};
const steps = [
  {
    label: strings.yourProfile,
    path: 'your-profile',
    order: 1,
  },
  {
    label: strings.driver,
    path: 'driver',
    order: 2,
  },
  {
    label: strings.irons,
    path: 'irons',
    order: 3,
  },
  {
    label: strings.wedges,
    path: 'wedges',
    order: 4,
  },
  {
    label: strings.finishingTouches,
    path: 'finishing-touches',
    order: 5,
  },
  {
    label: strings.results,
    path: 'results',
    order: 6,
  },
];

class Stepper extends Component {
  /*
  state = {
    zipcode: '',
  }
  */
  static propTypes = {
    step: PropTypes.string,
  };
  // zipcode taken away from here
  renderNavigator() {
    const {
      dispatch,
      history,
      savedValues,
      loggedInUser,
      formik: { values },
    } = this.props;

    const currentStepData = this.getCurrentStepData();


    const onStepClick = async step => {
      let isValid = true;
      if (currentStepData.path === 'your-profile') {
        isValid = await validateProfileStep(this.props.formik);
      }
      switch(currentStepData.path){
        case 'driver':
          var tr_clean = null;
          if(values.driver_height_pref_calc){
            tr_clean = heightPrefLabelMap[values.driver_height_pref_calc].replace(/\n/g, " ").trim();
          }
          const tp_clean = heightPrefLabelMap[values.driver_height_pref].replace(/\n/g, " ").trim();
          const wi_clean = windConditionsLabelMap[values.driver_wind_imp].replace(/\n/g, " ").trim();
          var dSpinPost = values.driver_spin_type.toString();
          dSpinPost = dSpinPost.charAt(0).toUpperCase() + dSpinPost.slice(1);
          var dLaunchAnglePost = values.driver_launch_type.toString();
          dLaunchAnglePost = dLaunchAnglePost.charAt(0).toUpperCase() + dLaunchAnglePost.slice(1);
          let driverData = {
            total_distance: values.driver_distance,
            launch_angle: dLaunchAnglePost,
            launch_angle_degree: values.driver_launch_angle,
            spin_rate: dSpinPost,
            spin_rate_rpm: values.driver_spin_rate,
            ball_speed_mph: values.driver_ball_speed,
            recommended_trajectory: tr_clean,
            trajectory_preference: tp_clean,
            wind_importance:wi_clean ,
            fitting_id: null

          };

          window.analytics.track("Player Profile Driver", driverData);
          window.gtag('event', 'Player Profile Driver', driverData);
          break;
        case 'your-profile':
          let profileData = {
            handicap: values.handicap,
            currentball: {
              brand: values.brand,
              model: values.model,
              year: values.year
            },
            playing_postal_code: null,
            fitting_id: null
          };
          window.analytics.track("Player Profile", profileData);
          window.gtag('event', 'Player Profile', profileData);
          break;
        case 'irons':
          const sp_clean = stoppingPowerLabelMap[values.iron_stop_pref].replace(/\n/g, " ");
          const w_clean = workabilityLabelMap[values.iron_workability_pref];
          const fp_clean = flierPreventionLabelMap[values.iron_flier_imp].replace(/\n/g, " ");
          var rla_clean = null; 
          if(values.iron_stop_pref_calc){
            rla_clean = stoppingPowerLabelMap[values.iron_stop_pref_calc];
          }
          var iLaunchAnglePost = values.iron_launch_type.toString();
          iLaunchAnglePost = iLaunchAnglePost.charAt(0).toUpperCase() + iLaunchAnglePost.slice(1);
          var iSpinPost = values.iron_spin_type.toString();
          iSpinPost = iSpinPost.charAt(0).toUpperCase() + iSpinPost.slice(1);
          let ironData = {
            fitting_id: null,
            carry_distance: values.iron_distance,
            launch_angle: iLaunchAnglePost,
            launch_angle_degree: values.iron_launch_angle,
            spin_rate: iSpinPost,
            spin_rate_rpm: values.iron_spin_rate,
            ball_speed_mph: values.iron_ball_speed,
            recommended_landing_angle: rla_clean,
            landing_angle_preference: sp_clean,
            workability_preference: w_clean,
            flier_prevention_importance: fp_clean
          };
          window.analytics.track("Player Profile Irons", ironData);
          window.gtag('event', 'Player Profile Irons', ironData);
          break;
        case 'wedges':
          let wedgeData = {
            fitting_id: null,
            full_wedge_spin_preference: spinPreferenceLabelMap[values.wedge_spin_pref],
            greenside_spin_importance: greenSpinPreferenceMap[values.greenside_spin_imp],
          };
          window.analytics.track("Player Profile Wedges", wedgeData);
          window.gtag('event', 'Player Profile Wedges', wedgeData);
          break;
        case 'finishing-touches':
          let touchData = {
            ball_feel_preference: values.ball_firmness_matters,
            putter_feel_preference: values.ball_firmness_matters ? putterFeelLabelMap[values.putter_feel_pref] : null,
            price_preference: values.cost_preference === 'strict' ? "Best Ball for Price" : "Best Ball for Game",
            fitting_id: null
          };
          if (values.cost_preference === 'strict') {
            touchData.price_lower = values.cost_lower_limit + 1;
            touchData.price_higher = values.cost_upper_limit + 1;
          }
          window.analytics.track("Finishing Touches", touchData);
          window.gtag('event', 'Finishing Touches', touchData);
          break;
          
        case 'results':
          //tracking here done within Confidence Range Disclaimer
          break;
        default:
          console.log("Stagnant");
      }
      if (isValid) {
        dispatch(setQuestionnaireValues(values));
        calculateLaunchConditionsIfNeeded('driver', values, savedValues.toJS(), dispatch, loggedInUser);
        calculateLaunchConditionsIfNeeded('iron', values, savedValues.toJS(), dispatch, loggedInUser);

        history.push(`/my-fitting/${step.path}`);
      }
    };

    const getAriaStepProps = step => {
      const stepProps = {};
      if (step.path === this.props.step) {
        stepProps['aria-current'] = 'step';
      }

      return stepProps;
    };

    return (
      <div className="stepper--navigator">
        {steps.map((step, index) => (
          <Fragment key={step.path}>
            <div className="stepper--step">{step.label}</div>
          </Fragment>
        ))}
        {steps.map((step, index) => (
          <Fragment key={`${index + step.path}`}>
            <div
              className={classNames(
                'step-connector',
                step.path === this.props.step ? 'selected' : 'not-selected',
                currentStepData.order <= index ? 'not-passed' : 'passed'
              )}
            >
              <button
                type="button"
                onClick={() => onStepClick(step)}
                aria-label={step.label}
                {...getAriaStepProps(step)}
              >
                <div className="step-circle">
                  <div className="selected-dot" />
                </div>
              </button>
              <div className={`line ${index === 0 ? 'first' : index + 1 === steps.length ? 'last' : ''}`} />
            </div>
          </Fragment>
        ))}
      </div>
    );
  }

  renderMobileNavigator() {
    const { history } = this.props;

    const currentStepData = this.getCurrentStepData();

    return (
      <div className="stepper--navigator__mobile">
        <select
          name="mobile-steper"
          onChange={ev => history.push(`/my-fitting/${ev.target.value}`)}
          value={currentStepData.path}
        >
          {steps.map((step, index) => (
            <option key={`mb-${step.path}`} value={step.path}>
              {step.label}
            </option>
          ))}
        </select>
      </div>
    );
  }

  getCurrentStepData() {
    return steps.find(step => step.path === this.props.step);
  }

  render() {
    if (!this.getCurrentStepData()) {
      return null;
    }

    return (
      <div className="stepper__wrapper" tabIndex={-1}>
        {this.renderNavigator()}
        {this.renderMobileNavigator()}
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    savedValues: state.questionnaire.values,
    loggedInUser: state.auth.loggedInUser,
  })),
  formikConnect,
  withRouter
)(Stepper);
