import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import { Link, NavLink, withRouter } from 'react-router-dom';
import Logo from '../common/components/logo/Logo';
import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
  en: {
    coBranding: 'Co-Branding',
    fitters: 'Fitters',
    logout: 'Logout',
    settings: 'Settings',
    vouchers: 'Free Fittings',
    debug: 'Debug',
  },
});

class AdminLayout extends Component {
  componentDidMount() {
    const { loggedInUser, history } = this.props;

    if (loggedInUser.get('type') !== 'admin') {
      history.push('/');
    }
  }

  render() {
    return (
      <div className="admin-layout">
        <div className="admin-header">
          <div className="container">
            <div className="admin-header__inner">
              <div className="admin-logo__container">
                <Logo width={171} height={40} />
                <div className="admin-tag">ADMIN</div>
              </div>
              <Link className="logout-link" to="/logout">
                {strings.logout}
              </Link>
            </div>
          </div>
        </div>
        <div className="admin-header__nav">
          <div className="container">
            <div className="nav-links">
              <NavLink to="/admin" exact className="admin-header__nav-icon">
                {strings.debug}
              </NavLink>
              <NavLink to="/admin/fitters" exact className="admin-header__nav-icon">
                {strings.fitters}
              </NavLink>
              <NavLink to="/admin/co-branding" exact className="admin-header__nav-icon">
                {strings.coBranding}
              </NavLink>
              <NavLink to="/admin/vouchers" exact className="admin-header__nav-icon">
                {strings.vouchers}
              </NavLink>
            </div>
          </div>
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    loggedInUser: state.auth.loggedInUser,
  })),
  withRouter
)(AdminLayout);
