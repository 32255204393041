import React from 'react';
import LocalizedStrings from 'react-localization';
import LoadingIndicator from '../../common/components/LoadingIndicator';
import moment from 'moment';

const strings = new LocalizedStrings({
  en: {
    userDetails: 'User Details',
    email: 'Email',
    createdAt: 'Created At',
    emailVerified: 'Email Verified',
    yes: 'Yes',
    no: 'No',
    payments: 'Payments',
    code: 'Code',
    status: 'Status',
    date: 'Date',
    amount: 'Amount',
    transactionId: 'Transaction ID',
    noData: 'No data available',
    loading: 'Loading data...',
    profile: 'Profile',
    fittingResults: 'Fitting Results',
    receivedShares: 'Received Fitting Codes',
    shares: 'Shared Fitting Codes',
    redeemed: 'REDEEMED',
    redeemNow: 'REDEEM NOW',
    viewResults: 'View Results',
    viewAll: 'View All',
  },
});

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
};

const UserDetails = ({
  userEmail,
  createdAt,
  emailVerified,
  shares,
  loading,
  userProfile,
  fittingResults,
  receivedShares,
  isRegisteredUser
}) => {
  return (
    <div
      className="user-details-modal"
      style={{ paddingBottom: '24px' }}
    >
      {loading ? (
        <div className="user-details-modal__loading">
          <LoadingIndicator />
          <p>{strings.loading}</p>
        </div>
      ) : (
        <div className="user-details-modal__content">
          <div className="my-account-sections-grid">
            {/* Profile Section - Only show for registered users */}
            {isRegisteredUser ? (
              <div className="my-account-section">
                <div className="my-account-section-header">
                  <h2>{strings.profile}</h2>
                </div>
                <div className="my-account-profile-info">
                  <div>{userProfile ? `${userProfile.first_name || ''} ${userProfile.last_name || ''}` : ''}</div>
                  <div>{userEmail}</div>
                  <div>{`Created: ${formatDate(createdAt)}`}</div>
                  <div>{`Email Verified: ${emailVerified ? strings.yes : strings.no}`}</div>
                  {userProfile && (
                    <>
                      <div>{`Current ball: ${userProfile.current_ball_brand || ''} ${userProfile.current_ball_model || '-'} ${userProfile.current_ball_year || ''}`}</div>
                      <div>{`Handicap: ${userProfile.handicap || '-'}`}</div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className="my-account-section">
                <div className="my-account-section-header">
                  <h2>Guest User Info</h2>
                </div>
                <div className="my-account-profile-info">
                  <div>{userEmail}</div>
                </div>
              </div>
            )}

            {/* Shares Section - Only show if has shares */}
            {shares && shares.length > 0 && (
              <div className="my-account-section">
                <div className="my-account-section-header">
                  <h2>{strings.shares}</h2>
                </div>
                <div className="my-account-results-list">
                  {shares.slice(0, 5).map((share, index) => (
                    <div key={index} className="my-account-result-item">
                      <span>
                        <strong>{share.voucher_code}</strong> shared to <strong>{share.email}</strong> on <strong>{formatDate(share.created_at)}</strong>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Received Shares Section - Only show if has received shares */}
            {receivedShares && receivedShares.length > 0 && (
              <div className="my-account-section">
                <div className="my-account-section-header">
                  <h2>{strings.receivedShares}</h2>
                </div>
                <div className="my-account-results-list">
                  {receivedShares.map((receivedShare, index) => (
                    <div key={index} className="my-account-result-item">
                      <span>
                        <strong>{receivedShare.voucher_code}</strong> received from <strong>{receivedShare.sender}</strong> on <strong>{formatDate(receivedShare.created_at)}</strong>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Fitting Results Section - Only show if has results */}
            {fittingResults && fittingResults.length > 0 && (
              <div className="my-account-section">
                <div className="my-account-section-header">
                  <h2>{strings.fittingResults}</h2>
                </div>
                <div className="my-account-results-list">
                  {fittingResults.slice(0, 5).map((result, index) => (
                    <div key={index} className="my-account-result-item">
                      <span>
                        {moment(result.created_at).format('MM/DD/YYYY @ hh:mma')}
                        {result.voucher ? `, redeemed ` : ''}
                        {result.voucher && <strong>{result.voucher}</strong>}
                      </span>
                      <button
                        onClick={() => window.open(result.url, '_blank')}
                        className="view-results-button"
                      >
                        {strings.viewResults}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetails;