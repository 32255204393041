import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import LocalizedStrings from 'react-localization';
import config from '../../config';

let strings = new LocalizedStrings({
  en: {
    downloadCsv: 'Download CSV',
    downloadFilteredCsv: 'Download Filtered CSV',
  },
});

class FitterCsvDownloadButton extends Component {
  handleDownload = async () => {
    const { token, selectedAccountFilter } = this.props;
    const filterActive = selectedAccountFilter !== null;

    const query = filterActive
      ? new URLSearchParams({ token, filter: selectedAccountFilter }).toString()
      : new URLSearchParams({ token }).toString();

    try {
      const response = await fetch(`${config.apiUrl}fitters/csv?${query}`, {
        headers: {
          'client-token': process.env.REACT_APP_CLIENT_TOKEN || 'INVALID_TOKEN',
        }
      });
      
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'fitters.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  render() {
    const { selectedAccountFilter } = this.props;
    const filterActive = selectedAccountFilter !== null;

    return (
      <button 
        className="button button--color--primary download-csv-button"
        onClick={this.handleDownload}
      >
        {filterActive ? strings.downloadFilteredCsv : strings.downloadCsv}
      </button>
    );
  }
}

export default compose(
  connect(state => ({
    token: state.auth.token,
  }))
)(FitterCsvDownloadButton);
