import React, { Component } from 'react';
import { Switch, Route } from 'react-router';

import CoBranding from './CoBranding';
import CoBrandAdd from './CoBranding/CoBrandAdd';
import ManageUsers from './ManageUsers';
import ManagerVouchers from './ManagerVouchers';
import Debugging from './Debugging';
import AdminLayout from './AdminLayout';
import CoBrandEdit from './CoBranding/CoBrandEdit';

class Admin extends Component {
  render() {
    return (
      <Route>
        <AdminLayout>
          <Switch>
            <Route exact path="/admin" component={Debugging} />
            <Route exact path="/admin/fitters" component={ManageUsers} />
            <Route exact path="/admin/vouchers" component={ManagerVouchers} />
            <Route exact path="/admin/co-branding" component={CoBranding} />
            <Route exact path="/admin/co-branding/add" component={CoBrandAdd} />
            <Route exact path="/admin/co-branding/:coBrandId" component={CoBrandEdit} />
          </Switch>
        </AdminLayout>
      </Route>
    );
  }
}

export default Admin;
