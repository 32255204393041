import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import URLSearchParams from '@ungap/url-search-params';
import Dinero from 'dinero.js';

import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';

import packageJson from '../package.json';
import registerServiceWorker from './service-worker-registration';
import App from './App';
import './index.css';
import 'react-toggle/style.css';
import config from './config';
import store from './store';

Dinero.globalLocale = 'en-US';
Dinero.defaultCurrency = 'USD';

// Polyfill URLSearchParams so fetch-client works in IE
window.URLSearchParams = URLSearchParams;

// Polyfill String.startsWith
if (!String.prototype.startsWith) {
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(String.prototype, 'startsWith', {
    value: function(search, rawPos) {
      var pos = rawPos > 0 ? rawPos | 0 : 0;
      return this.substring(pos, pos + search.length) === search;
    },
  });
}

// Sentry init
if (config.sentry && config.sentry.dsn) {
  // Determine environment to set appropriate sampling rates
  const environment = process.env.REACT_APP_ENV;
  const isProd = environment === 'prod' || environment === 'production';

  Sentry.init({
    dsn: config.sentry.dsn,
    environment: environment,
    release: packageJson.version,
    // Enable performance monitoring with appropriate sampling rates
    // Lower values in production to reduce overhead
    tracesSampleRate: isProd ? 0.2 : 1.0,
    profilesSampleRate: isProd ? 0.1 : 0.5,
    // Configure session replay with environment-specific settings
    replaysSessionSampleRate: isProd ? 0.1 : 0.5,
    replaysOnErrorSampleRate: isProd ? 0.5 : 1.0,
    // Enable all relevant integrations
    integrations: [
      Sentry.replayIntegration({
        // Mask sensitive data in production
        maskAllText: isProd,
        blockAllMedia: isProd,
      }),
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.feedbackIntegration()
    ],
    // Configure which requests to trace
    tracePropagationTargets: [
      'https://develop.ballfitting.com',
      'https://stage.ballfitting.com',
      'https://ballfitting.com',
      /^\/api/,
    ],
    // Enable distributed tracing
    enableTracing: true,
  });
}

// gtag
window.dataLayer = window.dataLayer || [];
window.gtag =
  window.gtag ||
  function() {
    window.dataLayer.push(arguments);
  };

const loadGtag = callback => {
  // we need to add some stuff for consent mode here!!
  const existingScript = document.getElementById('gtag');
  // or here!
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `//www.googletagmanager.com/gtag/js?id=${config.analytics.measurementId}`;
    script.id = 'gtag';
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

const onGtagLoaded = () => {
  window.gtag('js', new Date());
  window.gtag('config', config.analytics.measurementId);
};

loadGtag(onGtagLoaded);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <div>
        <Route component={App} />
      </div>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

registerServiceWorker();
